class ToggleNav {
    constructor(selector) {
        this.element = selector;
        this.nav = document.querySelector('.js-nav');
        this.html = document.querySelector('html');

        this.init();
    }

    init() {
        // Open/Close Menu
        this.element.addEventListener('click', () => {
            this.toggleClasses();
        });

        // Close Menu when ESC key is pressed
        document.addEventListener('keydown', (e) => {
            if (this.html.classList.contains('nav-is-open')) {
                this.closeNav(e);
            }
        });
    }

    toggleClasses() {
        this.element.classList.toggle('is-clicked');
        this.element.setAttribute(
            'aria-expanded',
            this.element.getAttribute('aria-expanded') === 'true'
                ? 'false'
                : 'true',
        );
        this.html.classList.toggle('nav-is-open');
        this.nav.classList.toggle('is-open');
    }

    closeNav(e) {
        if (e.key === 'Escape') {
            this.toggleClasses();
        }
    }
}

export default ToggleNav;
