/*
  Project: maryslovart
  Author: Ewa Karaszkiewicz
 */

import ToggleNav from './modules/toggle-nav';

/* eslint-disable */

const toggleNav = document.querySelector('.js-toggle-nav');
if (toggleNav !== null) {
  new ToggleNav(document.querySelector('.js-toggle-nav'));
}

/* eslint-enable */
